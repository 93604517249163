<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                       <a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> SKU
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-light">
                                        <thead>
                                            <tr>
                                                <th v-for="(value, key) in columns">
                                                    <a href="#" class="text-capitalize tbl_th_a">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td>{{data.id}}</td>
                                                <td>{{data.skuCode}}</td>
                                                <td>{{data.variation}}</td>
                                                <td><img :src="env.mediaUrl+data.image1" class="tbl_image" /></td>
                                                <td><img :src="env.mediaUrl+data.image2" class="tbl_image" /></td>
                                                <td><img :src="env.mediaUrl+data.image3" class="tbl_image" /></td>
                                                <td><img :src="env.mediaUrl+data.image4" class="tbl_image" /></td>
                                                <td style="padding: 0px;">
                                                    <button v-b-modal.bv-modal-create class="btn btn-option text-primary"
                                                        @click="editData(data)" ><i class="fas fa-edit"></i></button>
                                                    <button v-b-modal.bv-modal-delete
                                                        class="btn btn-option text-danger"><i class="fas fa-trash-alt"
                                                            @click="deleteData(data)"></i></button>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="7" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">Are you sure to delete ? </p>
            <b-button class="mt-3 btn-danger" block @click="deleteItem()">Delete</b-button>
        </b-modal>
        <b-modal id="bv-modal-create" ref="my-modal" title="Add SKU" hide-footer style="width: 170%">
            <template v-slot:modal-name>
                Add SKU
            </template>
            <div class="d-block text-center">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <select class="form-control" v-model="skuRequest.variationId" @change="validationRule()">
                            <option value="">Choose Variation</option>
                            <option v-for="variation in variationList" :value="variation.id">{{variation.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="text" placeholder="SKU Code"  v-model="skuRequest.skuCode" @blur="validationRule()" class="form-control" id="inputCode">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <textarea v-model="skuRequest.description" @blur="validationRule()"
										class="form-control" id="inputdescription"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="file" class="form-control" id="inputImage" @change="readImage1">
                        <img v-if="currentImage1" :src="env.mediaUrl+currentImage1" class="banner-image mt-2" style="width: 50%;"/>
                        <img v-if="skuRequest.image1" v-bind:src="skuRequest.image1"
                            class="banner-image mt-2" style="width: 50%;"/>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="file" class="form-control" id="inputImage" @change="readImage2">
                        <img v-if="currentImage2" :src="env.mediaUrl+currentImage2" class="banner-image mt-2" style="width: 50%;"/>
                        <img v-if="skuRequest.image2" v-bind:src="skuRequest.image2"
                            class="banner-image mt-2" style="width: 50%;"/>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="file" class="form-control" id="inputImage" @change="readImage3">
                        <img v-if="currentImage3" :src="env.mediaUrl+currentImage3" class="banner-image mt-2" style="width: 50%;"/>
                        <img v-if="skuRequest.image3" v-bind:src="skuRequest.image3"
                            class="banner-image mt-2" style="width: 50%;"/>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="file" class="form-control" id="inputImage" @change="readImage4">
                        <img v-if="currentImage4" :src="env.mediaUrl+currentImage4" class="banner-image mt-2" style="width: 50%;"/>
                        <img v-if="skuRequest.image4" v-bind:src="skuRequest.image4"
                            class="banner-image mt-2" style="width: 50%;"/>
                    </div>
                </div>
            </div>
            <b-button class="mt-3 btn-success" block @click="onCreate()">Save</b-button>
        </b-modal>
        <router-link class="float" to="" v-b-modal.bv-modal-create @click="newData()">
            <i class="fa fa-plus my-float" style="padding: 37%;"></i>
        </router-link>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'itemList',
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                currentImage1: '',
                currentImage2: '',
                currentImage3: '',
                currentImage4: '',
                dataList: [],
                variationList: [],
                columns: {
                    id: "SKU ID",
                    skuCode: "Code",
                    variation: "variation",
                    image1: "Image1",
                    image2: "Image2",
                    image3: "Image3",
                    image4: "Image4",

                },
                skuRequest: {
                    productId: "",
                    variationId: "",
                    skuCode: "",
                    description: "",
                    image1: "",
                    image2: "",
                    image3: "",
                    image4: ""
                },
                filter: {
					page: "",
                    orderBy: "",
                    sortKey: ""
				},
                validationData: {
                    skuCode: "",
                    description: ""
				}
            }
        },
        watch: {
            '$route'(to, from) {
                this.fetchData()
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                skuListAction: 'skuListAction',
                skuDetailAction: 'skuDetailAction',
                skuDeleteAction: 'skuDeleteAction',
                skuStoreAction: 'skuStoreAction',
                skuUpdateAction: 'skuUpdateAction',
            }),
            readImage1({
                target
            }) {
                const files = target.files
                const fileReader = new FileReader()
                fileReader.readAsDataURL(files[0])
                fileReader.onload = e => {
                    this.skuRequest.image1 = e.target.result
                };
            },
            readImage2({
                target
            }) {
                const files = target.files
                const fileReader = new FileReader()
                fileReader.readAsDataURL(files[0])
                fileReader.onload = e => {
                    this.skuRequest.image2 = e.target.result
                };
            },
            readImage3({
                target
            }) {
                const files = target.files
                const fileReader = new FileReader()
                fileReader.readAsDataURL(files[0])
                fileReader.onload = e => {
                    this.skuRequest.image3 = e.target.result
                };
            },
            readImage4({
                target
            }) {
                const files = target.files
                const fileReader = new FileReader()
                fileReader.readAsDataURL(files[0])
                fileReader.onload = e => {
                    this.skuRequest.image4 = e.target.result
                };
            },
            async onCreate() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
                if(this.id){
                    this.skuRequest.id = this.id
                    let option = this.skuRequest
                    await this.skuUpdateAction({
                        ...option
                    }).then(res => {
                        this.fetchData()
                        this.$refs['my-modal'].hide()
                        this.isLoading = false;
                    }).catch(err => this.isLoading = true)
                }else{
                    let option = this.skuRequest
                    await this.skuStoreAction({
                        ...option
                    }).then(res => {
                        this.fetchData()
                        this.$refs['my-modal'].hide()
                        this.isLoading = false;
                    }).catch(err => this.isLoading = true)
                }

			},
            async getFields(){
				let option = {
					type: "store"
				}
				await this.skuDetailAction({
					...option
				}).then(res => {
					this.variationList = res.data.variation
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
            async fetchData() {
                this.isLoading = true
                let option = {
                    orderBy:"desc",
                    sortKey: "created_at",
                    limit: 10,
                    productId : this.skuRequest.productId
                    }
                await this.skuListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.$router.replace({ path: 'sku-list', query: {page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, productId:this.skuRequest.productId} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            back() {
				this.$router.replace({ path: 'product-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey} }).catch(()=>{})
			},
            deleteData(data) {
                this.id = data.id
            },
            newData() {
                this.id = ""
                this.currentImage1 = ""
                this.currentImage2 = ""
                this.currentImage3 = ""
                this.currentImage4 = ""
            },
            editData(data) {
                this.id = data.id
                this.currentImage1 = data.image1
                this.currentImage2 = data.image2
                this.currentImage3 = data.image3
                this.currentImage4 = data.image4
                this.skuRequest.variationId = data.variationId
                this.skuRequest.description = data.description
                this.skuRequest.skuCode = data.skuCode
            },
            async deleteItem() {
                this.isLoading = true
                let option = {
                    id: this.id,
                }
                await this.skuDeleteAction({
                    ...option
                }).then(res => {
                    this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
                }).catch(err => this.isLoading = true)
            },
            validationRule() {
				let isValidation = []
				if (this.skuRequest.skuCode == "") {
					this.validationData.skuCode = "The SKU Code field is required."
					isValidation.push(true)
				} else {
					this.validationData.skuCode = ""
					isValidation.push(false)
				}
				if (this.skuRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
            }

        },
        mounted() {
            this.filter.page = this.$route.query.page
            this.filter.orderBy = this.$route.query.orderBy
            this.filter.sortKey = this.$route.query.sortKey
            this.filter.prodictId = this.$route.query.prodictId
            this.skuRequest.productId = this.$route.query.productId
            this.fetchData()
            this.getFields()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
    .modal-content {
        width: 800px !important;
    }
</style>